::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
:root {
  --primary-color: #3a32d1;
}

body {
  background-color: #eef0f3;
}
.navbar {
  background-color: var(--primary-color);
}
.teams_page_warapper {
  min-height: 100vh;
  width: 100%;
}
.tab_bar_wrappar {
  border-radius: 24px;
}
.tab_bar_selected_menu {
  background-color: var(--primary-color);
  border-radius: 30px;
  color: #fff;
}
.facebook_wrapper {
  min-height: 500px;
  overflow: scroll;
}
.facebook_page {
  background-image: url("../public/static/facebook_cover.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.news_list_wrappar {
  border: 1px solid red;
}
.facebook_time_date {
  font-size: 10px;
  margin-left: 5px;
  color: #84878e;
}

.label_title {
  color: var(--primary-color);
}
.scoreboard_title {
  color: var(--primary-color);
}
.overflow-scroll-hidden {
  overflow: scroll;
}
.overflow-scroll-hidden::-webkit-scrollbar {
  width: 0px;
}
.overflow-scroll-hidden::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.overflow-scroll-hidden::-webkit-scrollbar-track {
  background-color: transparent;
}
.ads_cover{
  background-image: url('../public/static/ad_cover.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.ad_small_cover{
  background-image: url('../public/static/ad_cover_small.png');
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
}
.logo_text {
  position: relative;
  margin-right: 10px; 
}
.vertical-line {
 width: 10px;
 color: #fff;
}
.exclusively_videos{
  height: 150px;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Adjust the overlay color and opacity as needed */
}
.exclusively_videos .play-icon {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  color: white; 
  font-size: 45px; 
}
.current_Series_item{
  background-color: #F8F8F8;
}

.ads_container{
  width: 95%;
}
@media (min-width: 640px) {
  .ads_container {
      width: 90%;
  }
}

@media (min-width: 768px) {
  .ads_container {
      width: 80%;
  }
}

@media (min-width: 1024px) {
  .ads_container {
      width: 70%;
  }
}